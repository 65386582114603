@import "_variables";
@import "node_modules/jquery-ui/themes/smoothness/jquery-ui";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/sweetalert/dist/sweetalert";
@import "resources/assets/stylesheets/animate";
@import "resources/assets/js/plugins/owl.carousel/owl.carousel";
@import "resources/assets/js/plugins/owl.carousel/owl.theme";

html {
  overflow-y: scroll;
}
.inline-checkboxes, .inline-radiobuttons {
	display: inline-block;
	margin-right: 25px;
}
.dropdown-sort-menu > li > a, .dropdown-sort-menu > li > a {
    color: #615F5F;
    text-align: left;
}
.page-heading {
	margin: 2em 0 1em 0;
    color: #343434;
    font-weight: normal;
    font-family: 'Ultra', sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    text-shadow: 0 2px white, 0 3px #777;
    border-bottom: 5px solid #5A5858;
}
.container-block {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}
.content {
    text-align: center;
    display: inline-block;
}
.title {
    font-size: 96px;
}